import React, { Component } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Nav, Row, Col, Card } from "react-bootstrap";
import img1 from "../assets/images/promo/AkhirTahunAlmazRS.jpg";
import img2 from "../assets/images/promo/PromoAkhirTahunCortezCTTypeS.jpg";
import img3 from "../assets/images/promo/PromoAkhirTahunConferoS.jpg";

class PromoSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemClicked: 1,
			navItems: [
				{
					id: 1,
					name: "SEMUA",
					to: "/promo?d=all",
				},
				{
					id: 2,
					name: "ALMAZ",
					to: "/promo?d=almaz",
				},
				{
					id: 3,
					name: "CORTEZ",
					to: "/promo?d=cortez",
				},
				{
					id: 4,
					name: "CONFERO",
					to: "/promo?d=confero",
				},
				{
					id: 5,
					name: "FORMO",
					to: "/promo?d=formo",
				},
			],
			detailItems: [
				{
					id: 1,
					title: "Promo Akhir Tahun Almaz RS",
					period: "07 Nov - 31 Des 2022",
					img: img1,
					link: "",
				},
				{
					id: 2,
					title: "Promo Akhir tahun Cortez CT",
					period: "07 Nov - 31 Des 2022",
					img: img2,
					link: "",
				},
				{
					id: 3,
					title: "Promo Akhir tahun New Confero",
					period: "07 Nov - 31 Des 2022",
					img: img3,
					link: "",
				},
			],
		};
	}

	clickHandle = (e) => {
		this.setState({
			itemClicked: e.target.id,
		});
	};

	render() {
		const { navItems, itemClicked, detailItems } = this.state;
		return (
			<React.Fragment>
				<div className="promo-container p-5">
					<div className="promo-header d-flex flex-column align-items-center border-2 border-bottom">
						<div className="promo-title mb-3">
							<span className="promo-title-line"></span>
							<h2 className="promo-text">PROMO</h2>
							<span className="promo-title-line"></span>
						</div>
						<Nav className="promo-navigation">
							{navItems.map((navItem) => {
								return (
									<LinkRouter key={navItem.id} id={navItem.id} className={`nav-link ${navItem.id == itemClicked ? "promo_item_active" : ""}`} to={navItem.to} onClick={this.clickHandle}>
										{navItem.name}
										<span></span>
									</LinkRouter>
								);
							})}
						</Nav>
					</div>
					<div className="promo-body mt-3">
						<Row>
							{detailItems.map((item) => {
								return (
									<Col lg={4} md={6} className="p-3">
										<Card className="scaleUp">
											<Card.Header className="promo-img border-0 p-0 position-relative">
												<Card.Img src={item.img} />
												<div className="btnDetail">
													<Card.Link>Details</Card.Link>
												</div>
											</Card.Header>
											<Card.Body className="promo-desc">
												<h5>{item.title}</h5>
												<p>Periode : {item.period}</p>
											</Card.Body>
										</Card>
									</Col>
								);
							})}
						</Row>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PromoSection;
