import React, { Component } from "react";
import { Card, Carousel } from "react-bootstrap";
import almaz from "../../assets/images/almaz.png";
import almazRz from "../../assets/images/almazRz.png";
import cortezCt from "../../assets/images/cortezCt.png";
import newconferoS from "../../assets/images/NewconferoS.png";
import axios from "axios";

class MainBanner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
			data: [],
		};
	}

	componentDidMount = async () => {
		const base_url = "http://localhost:8000/api";

		const res = await axios.get(`${base_url}/banner`);
		if (res.data.code === 200) {
			this.setState({
				status: res.data.code,
				data: res.data.data,
			});
		}
	};

	render() {
		const { data } = this.state;
		return (
			<React.Fragment>
				{data == null || data == "" ? (
					<Card id="description" className="mx-3">
						<h1 className="w-100">WULING</h1>
						<h3 className="w-100">Better Life</h3>
						<p className="w-100 mt-3">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla ab in, quisquam tenetur iure explicabo, non tempore qui possimus blanditiis delectus nostrum placeat id perspiciatis nesciunt at rem ratione beatae.Architecto,
							doloribus recusandae quia magni alias aspernatur non perferendis, atque numquam odit ratione soluta repellendus quos expedita fuga, officiis voluptate eius iste? Enim, facere voluptates temporibus cum deleniti nemo commodi.
						</p>
						<Card.Link className="btnCustom w-50">Read More</Card.Link>
					</Card>
				) : (
					data.map((item, key) => {
						return (
							<Card id="description" className="mx-3">
								<h1 className="w-100">{item.title}</h1>
								<h3 className="w-100">{item.sub_title}</h3>
								<p className="w-100 mt-3">{item.description}</p>
								<Card.Link className="btnCustom w-50">Read More</Card.Link>
							</Card>
						);
					})
				)}

				<Carousel fade indicators={true} controls={false} id="slide" className="d-flex align-items-end mx-3" variant="dark">
					<Carousel.Item className="d-flex justify-content-center">
						<img className="d-block w-100" src={almaz} alt="First slide" />
					</Carousel.Item>
					<Carousel.Item className="d-flex justify-content-center">
						<img className="d-block w-100" src={almazRz} alt="Second slide" />
					</Carousel.Item>
					<Carousel.Item className="d-flex justify-content-center">
						<img className="d-block w-100" src={cortezCt} alt="Third slide" />
					</Carousel.Item>
					<Carousel.Item className="d-flex justify-content-center">
						<img className="d-block w-100" src={newconferoS} alt="Forth slide" />
					</Carousel.Item>
				</Carousel>
			</React.Fragment>
		);
	}
}

export default MainBanner;
