import React from "react";
import { Link as LinkScroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faSquareFacebook, faSquareInstagram, faSquareTwitter, faSquareYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
	return (
		<div className="footer-container" id="kontak">
			<div className="footer-content">
				<div className="flex-content">
					<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi dignissimos fuga totam incidunt natus animi iure reiciendis quae expedita!</p>
				</div>
				<div className="flex-content">
					<h3>Navigasi</h3>
					<LinkScroll to="banner" spy={false} offset={0} smooth={false} duration={3000}>
						Banner
					</LinkScroll>
					<LinkScroll to="preview" spy={false} offset={0} smooth={false} duration={3000}>
						Preview
					</LinkScroll>
					<LinkScroll to="galeri" spy={false} offset={0} smooth={false} duration={3000}>
						Galeri Oto
					</LinkScroll>
					<LinkScroll to="event" spy={false} offset={0} smooth={false} duration={3000}>
						Event
					</LinkScroll>
					<LinkScroll to="profil" spy={false} offset={0} smooth={false} duration={3000}>
						Profil
					</LinkScroll>
				</div>
				<div className="flex-content">
					<h3>Alamat</h3>
					<h6>
						<FontAwesomeIcon icon={faMapLocationDot} /> Jl. Pengayoman Baru No.2
					</h6>
					<h6>
						<FontAwesomeIcon icon={faPhone} /> 0852xxxxxxxx
					</h6>
				</div>
				<div className="flex-content">
					<h3>Sosmed</h3>
					<FontAwesomeIcon icon={faSquareFacebook} />
					<FontAwesomeIcon icon={faSquareInstagram} />
					<FontAwesomeIcon icon={faSquareTwitter} />
					<FontAwesomeIcon icon={faSquareYoutube} />
				</div>
			</div>
			<div className="credit">
				<span></span>
				<h6>&copy; Copyright 2022 | By Codaeng.com</h6>
			</div>
		</div>
	);
};

export default Footer;
