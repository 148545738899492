import React, { Component } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Card, Row, Col, Carousel } from "react-bootstrap";
import almaz from "../../assets/images/almaz.png";
import almazRz from "../../assets/images/almazRz.png";
import cortezCt from "../../assets/images/cortezCt.png";

import img1 from "../../assets/images/promo/AkhirTahunAlmazRS.jpg";
import img2 from "../../assets/images/promo/PromoAkhirTahunCortezCTTypeS.jpg";
import img3 from "../../assets/images/promo/PromoAkhirTahunConferoS.jpg";

class ProdukDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
		};
	}

	render() {
		return (
			<React.Fragment>
				<div className="produkDetailContainer">
					<div className="prodDetailBanner">
						<img src={img3} />
					</div>
					<div className="prodDetailNav d-flex flex-column align-items-center p-5">
						<div className="navTitle d-flex flex-column align-items-center">
							<h1>Tipe Almaz RS</h1>
							<p className="mt-3 w-75 text-center">
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam illum quam consequuntur, nam quas qui officiis quisquam amet cupiditate quibusdam commodi modi atque possimus ducimus expedita sunt id! Id, accusantium.
							</p>
						</div>
						<div className="navItem mt-3 w-75">
							<Row>
								<Col md={6}>
									<Card className="prodDetailCard scaleUp">
										<Card.Img src={almaz} className="cardItemImg" />
										<Card.Body className="cardItemBody">
											<Card.Title>Almaz RS Pro</Card.Title>
											<LinkRouter>Selengkapnya</LinkRouter>
										</Card.Body>
									</Card>
								</Col>
								<Col md={6}>
									<Card className="prodDetailCard scaleUp">
										<Card.Img src={almazRz} className="cardItemImg" />
										<Card.Body className="cardItemBody">
											<Card.Title>Almaz RS EX</Card.Title>
											<LinkRouter>Selengkapnya</LinkRouter>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</div>
						<div className="prodDetailGeneralDesc mt-5">
							<div className="prodDetailTitle">
								<h1>Wuling Almaz RS</h1>
								<span className="titleBorder"></span>
							</div>
							<div className="prodDetailDescription mt-3">
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus officia odio non adipisci, quod itaque asperiores tenetur ratione aspernatur sed veniam. Nam laborum delectus illum debitis nostrum sint dignissimos
									quaerat! Asperiores, sint veritatis. Qui enim alias laudantium veritatis, saepe nesciunt quisquam a cupiditate hic ratione obcaecati fuga eius omnis asperiores voluptatum. Quidem illo dolor dolores facilis maiores hic
									tempora quibusdam?
								</p>
								<ul>
									<li>
										<div class="ms-2 me-auto">
											<div class="fw-bold">Lorem Ipsum</div>
											Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis error quod iusto ut reiciendis ex illum, deleniti facere corrupti, distinctio beatae tempore explicabo est quidem. Dolore nemo aperiam veritatis
											unde. Maxime laborum sunt itaque, placeat iure dolor magni eveniet cupiditate ipsum deserunt libero accusantium, quis eos et. Animi temporibus, sunt facere est minima, totam quo vitae illum accusamus quam excepturi.
										</div>
									</li>
									<li>
										<div class="ms-2 me-auto">
											<div class="fw-bold">Lorem Ipsum</div>
											Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis error quod iusto ut reiciendis ex illum, deleniti facere corrupti, distinctio beatae tempore explicabo est quidem. Dolore nemo aperiam veritatis
											unde. Maxime laborum sunt itaque, placeat iure dolor magni eveniet cupiditate ipsum deserunt libero accusantium, quis eos et. Animi temporibus, sunt facere est minima, totam quo vitae illum accusamus quam excepturi.
										</div>
									</li>
									<li>
										<div class="ms-2 me-auto">
											<div class="fw-bold">Lorem Ipsum</div>
											Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis error quod iusto ut reiciendis ex illum, deleniti facere corrupti, distinctio beatae tempore explicabo est quidem. Dolore nemo aperiam veritatis
											unde. Maxime laborum sunt itaque, placeat iure dolor magni eveniet cupiditate ipsum deserunt libero accusantium, quis eos et. Animi temporibus, sunt facere est minima, totam quo vitae illum accusamus quam excepturi.
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ProdukDetails;
