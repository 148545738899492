import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-bootstrap";

const Preview = () => {
	return (
		<React.Fragment>
			<div id="fSection" className="frame-section mx-3" data-aos="fade-right">
				<iframe width="560" height="315" src="https://www.youtube.com/embed/zWatMGJcgsE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
				<div id="iframe-shadow"></div>
			</div>
			<div className="desc-section mx-3">
				<h1 data-aos="fade-left" data-aos-delay="200">
					Better Life<span></span>
				</h1>
				<p data-aos="fade-left" data-aos-delay="500">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit, facere fuga alias totam magni a nostrum ex asperiores pariatur, cupiditate beatae, quaerat deleniti cumque eum quidem. Facere quasi distinctio accusantium?. Lorem
					ipsum dolor sit amet consectetur adipisicing elit. Velit, facere fuga alias totam magni a nostrum ex asperiores pariatur, cupiditate beatae, quaerat deleniti cumque eum quidem. Facere quasi distinctio accusantium?
				</p>
				<h5 className="btnAnimate" data-aos="fade-left" data-aos-delay="800">
					<NavLink>
						Lebih Banyak <FontAwesomeIcon icon={faArrowRightLong} />
					</NavLink>
				</h5>
			</div>
		</React.Fragment>
	);
};
export default Preview;
