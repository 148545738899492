import React, { Component } from "react";
import MainBanner from "./parts/Banner";
import Preview from "./parts/Preview";
import MainEvent from "./parts/Event";
import SalesProfile from "./parts/Sales";
import GalleryOto from "./parts/GalleryOto";

class HomeSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
		};
	}

	render() {
		return (
			<React.Fragment>
				<div className="banner-container p-5" id="banner">
					<MainBanner />
				</div>
				<div className="preview-container p-5" id="preview">
					<Preview />
				</div>
				<div className="galleryOto-container p-5" id="galeri">
					<GalleryOto />
				</div>
				<div className="event-container p-5" id="event">
					<MainEvent />
				</div>
				<div className="sales-container" id="profil">
					<SalesProfile />
				</div>
			</React.Fragment>
		);
	}
}

export default HomeSection;
