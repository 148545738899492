import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import logo from "../../wuling-logo.svg";
import { Container, Navbar, Nav } from "react-bootstrap";

class NavigationBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sticky: null,
			isClick: null,
			links: [
				{
					id: 1,
					name: "HOME",
					to: "/",
				},
				{
					id: 2,
					name: "PRODUK",
					to: "/produk",
				},
				{
					id: 3,
					name: "PROMO",
					to: "/promo",
				},
			],
		};
	}

	handleScroll = () => {
		this.setState({
			sticky: window.scrollY > 70,
		});
	};

	componentDidMount = () => {
		window.addEventListener("scroll", this.handleScroll);
	};

	componentWillUnmount = () => {
		window.removeEventListener("scroll", this.handleScroll);
	};
	clickHandle = (data) => {
		this.setState({
			isClick: data,
		});
	};

	render() {
		const { links, isClick, sticky } = this.state;
		return (
			<div className={`navbar-container shadow-sm ${sticky ? "sticky" : ""}`} id="navbar">
				<Navbar expand="lg">
					<Container>
						<Navbar.Brand href="/">
							<img src={logo} className="App-Brand" alt="logo" />
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav>
								{links.map((link) => {
									return (
										<LinkRouter key={link.id} id={link.id} className={`nav-link ${link.id == isClick ? "active" : ""}`} to={link.to} onClick={this.clickHandle(this.id)}>
											{link.name}
										</LinkRouter>
									);
								})}
								<LinkScroll className="nav-link" to="kontak" spy={false} offset={0} smooth={false} duration={3000}>
									KONTAK
								</LinkScroll>
							</Nav>
							<Nav id="btnNavContainer" className="w-100 flex-row justify-content-end">
								<LinkRouter to="/calc" id="btnCalc" className="btnNav text-white" onClick={this.clickHandle(null)}>
									<FontAwesomeIcon icon={faCalculator} />
								</LinkRouter>
								<Nav.Link href="https://appsales.galeriide.net/" target="_blank" id="btnLogin" className="btnNav text-white">
									LOGIN
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		);
	}
}

export default NavigationBar;
