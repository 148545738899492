import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import img1 from "../../assets/images/promo/AkhirTahunAlmazRS.jpg";
import img2 from "../../assets/images/promo/PromoAkhirTahunCortezCTTypeS.jpg";
import img3 from "../../assets/images/promo/PromoAkhirTahunConferoS.jpg";

const MainEvent = () => {
	return (
		<React.Fragment>
			<h1>Event Terbaru</h1>
			<p className="subTitleSection">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
			<span></span>
			<div className="event-content">
				<Row>
					<Col sm={6} lg={4} className="p-3" data-aos="fade-down" data-aos-delay="100">
						<Card className="shadow border-0">
							<Card.Img src={img1} />
							<Card.Body>
								<Card.Title>Wuling Live Day, #AlmazRSTheRisingStar</Card.Title>
								<Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col sm={6} lg={4} className="p-3" data-aos="fade-down" data-aos-delay="400">
						<Card className="shadow border-0">
							<Card.Img src={img2} />
							<Card.Body>
								<Card.Title>Wuling Live Day, #AlmazRSTheRisingStar</Card.Title>
								<Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col sm={6} lg={4} className="p-3" data-aos="fade-down" data-aos-delay="700">
						<Card className="shadow border-0">
							<Card.Img src={img3} />
							<Card.Body>
								<Card.Title>Wuling Live Day, #AlmazRSTheRisingStar</Card.Title>
								<Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};

export default MainEvent;
