import React, { Component } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Nav, Row, Col, Card } from "react-bootstrap";
import almaz from "../assets/images/almaz.png";
import almazRz from "../assets/images/almazRz.png";
import cortezCt from "../assets/images/cortezCt.png";

class ProdukSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemClicked: 1,
			navItems: [
				{
					id: 1,
					name: "SEMUA",
					to: "/produk?d=all",
				},
				{
					id: 2,
					name: "ALMAZ",
					to: "/produk?d=almaz",
				},
				{
					id: 3,
					name: "CORTEZ",
					to: "/produk?d=cortez",
				},
				{
					id: 4,
					name: "CONFERO",
					to: "/produk?d=confero",
				},
				{
					id: 5,
					name: "FORMO",
					to: "/produk?d=formo",
				},
			],
			detailsItem: [
				{
					id: 1,
					img: almaz,
					name: "ALMAZ RS",
					variant: "1.5 T Pro / 1.5 T EX 5 Seats / 1.5 T EX 7 Seats",
					discountPercentage: "20% OFF",
					discountPrice: "Rp. 392.800.000",
					normalPrice: "Rp. 471.360.000",
					to: `/produk/detail`,
				},
				{
					id: 2,
					img: almazRz,
					name: "ALMAZ RS",
					variant: "1.5 T Pro / 1.5 T EX 5 Seats / 1.5 T EX 7 Seats",
					discountPercentage: "20% OFF",
					discountPrice: "Rp. 392.800.000",
					normalPrice: "Rp. 471.360.000",
					to: `/produk/detail`,
				},
				{
					id: 1,
					img: cortezCt,
					name: "ALMAZ RS",
					variant: "1.5 T Pro / 1.5 T EX 5 Seats / 1.5 T EX 7 Seats",
					discountPercentage: "20% OFF",
					discountPrice: "Rp. 392.800.000",
					normalPrice: "Rp. 471.360.000",
					to: `/produk/detail`,
				},
			],
		};
	}

	clickHandle = (e) => {
		this.setState({
			itemClicked: e.target.id,
		});
	};

	render() {
		const { navItems, detailsItem, itemClicked } = this.state;
		return (
			<React.Fragment>
				<div className="produk-container p-5">
					<div className="produk-header d-flex flex-column align-items-center border-2 border-bottom">
						<div className="produk-title mb-3">
							<span className="produk-title-line"></span>
							<h2 className="produk-text">PRODUK</h2>
							<span className="produk-title-line"></span>
						</div>
						<Nav className="produk-navigation">
							{navItems.map((navItem) => {
								return (
									<LinkRouter key={navItem.id} id={navItem.id} className={`nav-link ${navItem.id == itemClicked ? "produk_item_active" : ""}`} to={navItem.to} onClick={this.clickHandle}>
										{navItem.name}
										<span></span>
									</LinkRouter>
								);
							})}
						</Nav>
					</div>
					<div className="produk-body mt-3">
						<Row>
							{detailsItem.map((detailItem) => {
								return (
									<Col lg={4} md={6} className="p-3">
										<Card className="scaleUp">
											<Card.Header className="produk-img p-0 border-0  position-relative">
												<Card.Img src={detailItem.img} />
												<div className="btnDetail">
													<LinkRouter className="card-link" to={detailItem.to}>
														Details
													</LinkRouter>
												</div>
											</Card.Header>
											<Card.Body className="produk-desc">
												<h5>{detailItem.name}</h5>
												<p>{detailItem.variant}</p>
												<p className="discon-percent">{detailItem.discountPercentage}</p>
												<div className="price">
													<h6 className="discon-price">{detailItem.discountPrice}</h6>
													<h6 className="normal-price">{detailItem.normalPrice}</h6>
												</div>
											</Card.Body>
										</Card>
									</Col>
								);
							})}
						</Row>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ProdukSection;
