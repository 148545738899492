import React, { Component } from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

class CalcSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
		};
	}

	render() {
		return (
			<React.Fragment>
				<div className="calc-container p-5 d-flex flex-column align-items-center">
					<div className="calc-header">
						<div className="calc-title flex-column">
							<h2 className="calc-text">SIMULASI KREDIT</h2>
							<p className="calc-desc mt-3">
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam illum quam consequuntur, nam quas qui officiis quisquam amet cupiditate quibusdam commodi modi atque possimus ducimus expedita sunt id! Id, accusantium.
							</p>
						</div>
					</div>
					<div className="calc-body mt-3">
						<Row>
							<Col md={6} className="p-2">
								<Form.Select aria-label="Default select example">
									<option>Pilih Produk</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</Form.Select>
							</Col>
							<Col md={6} className="p-2">
								<InputGroup>
									<InputGroup.Text id="basic-addon1">Rp.</InputGroup.Text>
									<Form.Control placeholder="Uang Muka" aria-label="Username" aria-describedby="basic-addon1" />
								</InputGroup>
							</Col>
							<Col md={6} className="p-2">
								<Form.Select aria-label="Default select example">
									<option>Jangka Waktu Pembayaran</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</Form.Select>
							</Col>
							<Col md={6} className="p-2">
								<InputGroup>
									<Form.Control placeholder="Suku Bunga Per tahun" aria-label="Recipient's username" aria-describedby="basic-addon2" />
									<InputGroup.Text id="basic-addon2">%</InputGroup.Text>
								</InputGroup>
							</Col>
						</Row>
					</div>
					<div className="calc-footer mt-3">
						<div className="calc-footer-title">
							<h3>Hasil Kalkulasi</h3>
							<span className="calc-footer-title-line"></span>
						</div>
						<div className="calc-footer-form mt-3">
							<Row>
								<Col md={4}>
									<Form.Label htmlFor="basic-url">Harga Mobil Dipotong Dp</Form.Label>
									<InputGroup className="mb-3">
										<InputGroup.Text id="basic-addon3">Rp.</InputGroup.Text>
										<Form.Control id="basic-url" aria-describedby="basic-addon3" placeholder="ex: 80.000.000" />
									</InputGroup>
								</Col>
								<Col md={4}>
									<Form.Label htmlFor="basic-url">Uang Muka</Form.Label>
									<InputGroup className="mb-3">
										<InputGroup.Text id="basic-addon3">Rp.</InputGroup.Text>
										<Form.Control id="basic-url" aria-describedby="basic-addon3" placeholder="ex: 50.000.000" />
									</InputGroup>
								</Col>
								<Col md={4}>
									<Form.Label htmlFor="basic-url">Angsuran Perbulan</Form.Label>
									<InputGroup className="mb-3">
										<InputGroup.Text id="basic-addon3">Rp.</InputGroup.Text>
										<Form.Control id="basic-url" aria-describedby="basic-addon3" placeholder="ex: 2.000.000" />
									</InputGroup>
								</Col>
							</Row>
						</div>
						<div className="calc-footer-btn">
							<button className="btnCustom px-5">Hitung</button>
							<button className="btnReload ms-3">
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default CalcSection;
