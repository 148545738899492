import React from "react";
import { Routes, Route } from "react-router-dom";

import NavigationBar from "../components/parts/Navbar";
import Footer from "../components/parts/Footer";
import HomeSection from "../components/HomeSection";
import ProdukSection from "../components/ProdukSection";
import PromoSection from "../components/PromoSection";
import CalcSection from "../components/CalcSection";
import ProdukDetails from "../components/parts/ProdukDetails";

const RouteSection = () => {
	return (
		<React.Fragment>
			{/* Navbar Section */}
			<NavigationBar />
			{/* End of Navbar */}

			{/* Home Section */}
			<div className="content-container">
				<Routes>
					<Route path="/" element={<HomeSection />} />
					<Route path="/produk" element={<ProdukSection />} />
					<Route path="/produk/detail" element={<ProdukDetails />} />
					<Route path="/promo" element={<PromoSection />} />
					<Route path="/calc" element={<CalcSection />} />
				</Routes>
			</div>
			{/* End of Home */}

			{/* Footer Section */}
			<Footer />
			{/* End of Footer */}
		</React.Fragment>
	);
};

export default RouteSection;
