import React, { Component } from "react";
import { Card } from "react-bootstrap";
import almaz from "../../assets/images/almaz.png";
import almazRz from "../../assets/images/almazRz.png";
import cortezCt from "../../assets/images/cortezCt.png";

// swiper bundle
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

class GalleryOto extends Component {
	render() {
		return (
			<React.Fragment>
				<h1>Galeri OTOSales</h1>
				<p className="subTitleSection">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
				<span></span>
				<div className="galleryOto-carousel p-3">
					<Swiper
						loop={true}
						centeredSlides={false}
						grabCursor={true}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						spaceBetween={20}
						slidesPerView={3}
						navigation={false}
						breakpoints={{
							320: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							1024: {
								slidesPerView: 2,
								spaceBetween: 30,
							},
						}}
						modules={[Navigation, Autoplay]}
						className="mySwiper">
						<SwiperSlide>
							<Card className="w-100">
								<Card.Img src={almaz} />
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card className="w-100">
								<Card.Img src={almazRz} />
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card className="w-100">
								<Card.Img src={cortezCt} />
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card className="w-100">
								<Card.Img src={cortezCt} />
							</Card>
						</SwiperSlide>
					</Swiper>
				</div>
			</React.Fragment>
		);
	}
}

export default GalleryOto;
