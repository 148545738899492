import React from "react";
import { Card } from "react-bootstrap";
import sales from "../../assets/images/sales.png";

const SalesProfile = () => {
	return (
		<React.Fragment>
			<div className="box bg-light">
				<Card className="p-5" data-aos="fade-right">
					<Card.Title>Web Developer</Card.Title>
					<Card.Subtitle>Iswan Ariyanto</Card.Subtitle>
					<Card.Text className="mt-3">
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate deleniti sapiente atque perferendis! Facere nostrum quo natus! Nostrum, consequuntur assumenda cumque velit ad voluptatum, inventore nesciunt quo quidem enim
						quod! Nemo ducimus perspiciatis nam unde, placeat officia esse, eaque laudantium pariatur facilis illum ipsum veniam consectetur, ab consequatur architecto? Corrupti praesentium repellendus eaque nihil hic eum aut dignissimos
						dicta ipsam!a
					</Card.Text>
					<Card.Link className="btnCustom w-50">Lihat Detail</Card.Link>
				</Card>

				<Card className=" px-5 position-relative">
					<Card.Img src={sales} data-aos="zoom-in-down" data-aos-delay="100" />
					<div className="box-content1 animate"></div>
					<div className="box-content2 animate"></div>
					<div className="box-content3 animate"></div>
				</Card>
			</div>
		</React.Fragment>
	);
};

export default SalesProfile;
